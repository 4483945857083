var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RoleCategories } from '../../../roleEnums';
import { TrailMapType } from '../../../trail-maps/models';
import { FieldData, RecordTypeData } from '../../meta-data-decorators';
const recordTypeId = 'ClaimTransaction';
export var ClaimTransactionCategoryEnum;
(function (ClaimTransactionCategoryEnum) {
    ClaimTransactionCategoryEnum["StatusChange"] = "S";
    ClaimTransactionCategoryEnum["Payment"] = "P";
    ClaimTransactionCategoryEnum["Reserve"] = "R";
    ClaimTransactionCategoryEnum["Recovery"] = "V";
})(ClaimTransactionCategoryEnum || (ClaimTransactionCategoryEnum = {}));
;
export const claimTransactionCategoryList = [
    { code: ClaimTransactionCategoryEnum.StatusChange, description: 'Status Change' },
    { code: ClaimTransactionCategoryEnum.Payment, description: 'Payment' },
    { code: ClaimTransactionCategoryEnum.Reserve, description: 'Reserve' },
    { code: ClaimTransactionCategoryEnum.Recovery, description: 'Recovery' },
];
export var ClaimTransactionPaymentTypeEnum;
(function (ClaimTransactionPaymentTypeEnum) {
    ClaimTransactionPaymentTypeEnum["Log"] = "log";
    ClaimTransactionPaymentTypeEnum["Check"] = "check";
})(ClaimTransactionPaymentTypeEnum || (ClaimTransactionPaymentTypeEnum = {}));
;
let ClaimTransaction = class ClaimTransaction {
    id;
    claimId;
    claim;
    transactionDate;
    status;
    bucket;
    category;
    amount;
    transactionType;
    payeeName;
    payeeAddress;
    checkNumber;
    paymentType;
    payeeContactId;
    payeeContact;
    invoiceNumber;
    invoiceDate;
    serviceFrom;
    serviceThru;
    notes;
    checkId;
    check;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimTransaction.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Claim' })
], ClaimTransaction.prototype, "claimId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Claim' })
], ClaimTransaction.prototype, "claim", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimTransaction.prototype, "transactionDate", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimTransaction.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: [], label: 'Bucket' })
], ClaimTransaction.prototype, "bucket", void 0);
__decorate([
    FieldData({
        recordTypeId, fieldType: 'codelist', codeList: claimTransactionCategoryList
    })
], ClaimTransaction.prototype, "category", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], ClaimTransaction.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'transaction-types' })
], ClaimTransaction.prototype, "transactionType", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimTransaction.prototype, "payeeName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address' })
], ClaimTransaction.prototype, "payeeAddress", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimTransaction.prototype, "checkNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: [{ code: ClaimTransactionPaymentTypeEnum.Log, description: 'Log Payment' }, { code: ClaimTransactionPaymentTypeEnum.Check, description: 'Check Payment' }] })
], ClaimTransaction.prototype, "paymentType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Contact' })
], ClaimTransaction.prototype, "payeeContactId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Contact' })
], ClaimTransaction.prototype, "payeeContact", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimTransaction.prototype, "invoiceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimTransaction.prototype, "invoiceDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimTransaction.prototype, "serviceFrom", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], ClaimTransaction.prototype, "serviceThru", void 0);
__decorate([
    FieldData({ recordTypeId })
], ClaimTransaction.prototype, "notes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Check' })
], ClaimTransaction.prototype, "checkId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Check' })
], ClaimTransaction.prototype, "check", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimTransaction.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimTransaction.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ClaimTransaction.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ClaimTransaction.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ClaimTransaction.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ClaimTransaction.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ClaimTransaction.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], ClaimTransaction.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], ClaimTransaction.prototype, "deletedUTC", void 0);
ClaimTransaction = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Claim Transaction',
        displayNamePlural: 'Claim Transactions',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true,
            docTemplates: true
        },
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false
        },
        roleCategory: RoleCategories.Claims,
        recordHistoryConfig: {
            enable: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.QuickAdd, TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage, TrailMapType.DataEntryStart],
    })
], ClaimTransaction);
export { ClaimTransaction };
