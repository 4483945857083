import {
  AvailableDataViews,
  TableSchemaField,
  TableSchemaFieldFilter,
  UserView,
} from 'portal-commons/dist/views/models';
import { FileDownloadResult } from 'portal-commons/dist/shared/file-download-result';
import { FilterType } from 'portal-commons/dist/data-filters/models';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { DataFormatService } from '../services/data-format.service';
import { DataModelRecordTypeField } from 'portal-commons/dist/data-model/record-types';
import { DataModelStoreService } from '../data-model/services/data-model.store';
import { GeneralPermissions } from 'portal-commons/dist/roleEnums';
import { GetViewRequest } from './models/get-view-request';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { TableSchemaService } from '../services/table-schema.service';
import { Viewer } from './models/viewer';
import { dataFilters } from 'app/shared/models/data-filter';
import { tableColumn } from 'app/modules/tb-datatables/models/models';
import { PaginatedTableOptions } from 'portal-commons/dist/data-table/models';
import { TableColumnService } from 'app/modules/tb-datatables/services/table-column.service';
import { View } from 'portal-commons/dist/data-model/record-types/view';

interface viewConfig {
  id: string;
  extraFilters: TableSchemaFieldFilter[];
}
@Injectable({
  providedIn: 'root',
})
export class DataViewsService {
  dm = inject(DataModelStoreService);
  tableColumnService = inject(TableColumnService);
  private _availableDataViews = new BehaviorSubject<AvailableDataViews>({
    systemViews: null,
    tenantViews: null,
    userViews: null,
  });
  public readonly AvailableDataViews = this._availableDataViews.asObservable();

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private schemaService: TableSchemaService,
    private formatService: DataFormatService,
    private dataModelStore: DataModelStoreService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createView(userBody: Partial<UserView>): Observable<any> {
    const path = '/api/views/create';

    return this.httpClient.post(path, userBody).pipe(shareReplay(1));
  }

  updateView(userBody: Partial<UserView>): Observable<any> {
    const path = '/api/views/update';

    return this.httpClient.post(path, userBody).pipe(shareReplay(1));
  }

  deleteView(view: UserView) {
    const path = '/api/views/delete';
    return this.httpClient.post(path, { id: view.id }).pipe(shareReplay(1));
  }

  getPaginatedViewResults(
    id: string,
    options: PaginatedTableOptions,
    extraFilters?: TableSchemaFieldFilter[],
  ): Observable<any | undefined> {
    const path = `/api/views/get-page-data`;
    const body = {
      id: id,
      options: options,
      extraFilters: extraFilters,
    };
    return this.httpClient.post<any[]>(path, body).pipe(shareReplay(1));
  }

  getViewerByIdForCurrentUser(id: string, from: number, size: number): Observable<Viewer> {
    const request: GetViewRequest = {
      id: id,
      from: from,
      size: size,
      tenant: this.authService.currentUser().tenant!,
    };
    return this.getViewer(request).pipe(shareReplay(1));
  }

  getViewer(request: GetViewRequest): Observable<Viewer> {
    let path = `/api/views/${request.id}`;
    if (request.from) {
      path = path + `?from=${request.from}`;
      if (request.size) {
        path = path + `&size=${request.size}`;
      }
    }

    // console.log('getting viewer');
    return this.httpClient.get<Viewer>(path).pipe(shareReplay(1));
  }

  getViewerWithRunTimeParams(
    viewID: string,
    from: number,
    size: number,
    extraFilters: TableSchemaFieldFilter[],
  ) {
    const path = `/api/views/runtime`;
    // console.log('getting viewer');
    const body = {
      id: viewID,
      from: from,
      size: size,
      extraFilters: extraFilters,
    };
    return this.httpClient.post<Viewer>(path, body).pipe(shareReplay(1));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  export(type: string, viewID: string, dataFilters: dataFilters[]) {
    const path = `/api/views/export/${type}`;
    const acceptType = 'application/json';
    const exportFilters = this.getRuntimeFilters([], dataFilters);
    const exportConfig: viewConfig = {
      id: viewID,
      extraFilters: exportFilters ?? [],
    };
    return this.httpClient.post<FileDownloadResult>(path, exportConfig, {
      headers: {
        'Content-Type': 'application/json',
        Accept: acceptType,
      },
    });
  }

  async getDataViewsForUser(recordTypes?: string[]): Promise<AvailableDataViews | null> {
    const result = await firstValueFrom(this.getAvailableDataViews());

    if (!recordTypes || recordTypes.length === 0) {
      return result;
    }

    recordTypes = recordTypes.map((f) => f.toLowerCase());

    if (result && result.systemViews) {
      result.systemViews = [
        ...result.systemViews.filter((f) => f && recordTypes!.includes(f.recordType.toLowerCase())),
      ];
    }
    if (result && result.tenantViews) {
      result.tenantViews = [
        ...result.tenantViews.filter((f) => f && recordTypes!.includes(f.recordType.toLowerCase())),
      ];
    }
    if (result && result.userViews) {
      result.userViews = [
        ...result.userViews.filter((f) => f && recordTypes!.includes(f.recordType.toLowerCase())),
      ];
    }

    return result;
  }

  getPaginatedUserViews(options: PaginatedTableOptions) {
    return this.httpClient
      .get<View[]>('/api/views', {
        params: {
          options: JSON.stringify(options),
        },
      })
      .pipe(shareReplay(1));
  }

  getAvailableDataViews() {
    return this.httpClient.get<AvailableDataViews>('/api/available-views').pipe(
      tap((response) => {
        this._availableDataViews.next(response);
      }),
      catchError((error: any) => {
        console.log(error.message);
        return of(null);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  runScheduler(): Observable<any> {
    const path = '/api/scheduler/run';
    return this.httpClient.post(path, {});
  }

  getViewConfig(id: string): Observable<UserView> {
    const path = `/api/views/config/${id}`;
    return this.httpClient.get<UserView>(path).pipe(shareReplay(1));
  }

  //not a long-term solution, but if we already have
  //the view config we don't need to get it again.
  canExportView(id: string, view?: UserView): Observable<boolean> {
    const config = view ? of(view) : this.getViewConfig(id);

    return config.pipe(
      switchMap((viewConfig) => {
        if (!!viewConfig.recordType) {
          if (this.dm.getRecordType(viewConfig.recordType)?.roleCategory) {
            return this.authService.hasPermission$(
              this.dm.getRecordType(viewConfig.recordType)!.roleCategory!,
              GeneralPermissions.ExportData,
            );
          }
          return of(true);
        }
        return of(true);
      }),
      catchError((err) => {
        console.error('Error getting view config', err);
        return of(false);
      }),
      shareReplay(1),
    );
  }

  async getExistingUserView(id: string) {
    const availableViews = await this.getDataViewsForUser();
    let union: UserView[] = [];
    if (!availableViews) {
      return union;
    }
    if (availableViews.systemViews) {
      union = [...union, ...(availableViews.systemViews as UserView[])];
    }
    if (availableViews.tenantViews) {
      union = [...union, ...(availableViews.tenantViews as UserView[])];
    }
    if (availableViews.userViews) {
      union = [...union, ...(availableViews.userViews as UserView[])];
    }
    return union.find((f) => f.id === id);
  }

  getRuntimeFilters(
    runtimeFilters: TableSchemaFieldFilter[] | undefined,
    dataFilters: dataFilters[],
  ): TableSchemaFieldFilter[] | undefined {
    if (runtimeFilters) {
      runtimeFilters = runtimeFilters.filter((e) => e.name != 'dynamicFilter');
      if (runtimeFilters.length == 0) {
        runtimeFilters = undefined;
      }
    }
    if (dataFilters && dataFilters.length > 0) {
      dataFilters.forEach((filterSet) => {
        filterSet.filter.forEach((filter) => {
          // console.log(filter);

          if (!runtimeFilters) {
            runtimeFilters = [];
          }

          const value: TableSchemaFieldFilter = {
            name: 'dynamicFilter',
            mappedObject: filter.Key,
            searchParameter1: filter.Value,
            filter: FilterType.Equals,
          };
          runtimeFilters.push(value);
        });
      });
    }
    return runtimeFilters;
  }

  getViewForViewer(
    id: string,
    runTimeFilters: TableSchemaFieldFilter[] | undefined,
    dataFilters: dataFilters[],
    startAt = 0,
    fetchSize = 300,
  ) {
    const useFilters = this.getRuntimeFilters(runTimeFilters, dataFilters) ?? [];
    const viewer$ = of(true).pipe(
      switchMap(() => {
        if (useFilters.length > 0) {
          return this.getViewerWithRunTimeParams(id, startAt, fetchSize, useFilters);
        }
        return this.getViewerByIdForCurrentUser(id, startAt, fetchSize);
      }),
    );

    return combineLatest([
      viewer$,
      this.dataModelStore.recordTypesLoaded$.pipe(filter((f) => f === true)),
    ]).pipe(
      map(([viewer, loaded]) => {
        return {
          viewer,
          columns: this.getTableColumnsFromViewConfig(viewer.config),
        };
      }),
      shareReplay(1),
    );
  }

  getTableColumnsFromViewConfig(view: UserView): tableColumn[] {
    const cols: tableColumn[] = [];
    if (view.viewType === 'sql') {
      return (view.customColumns ?? []) as tableColumn[];
    }
    if (view.viewType === 'summary' && view.groupFields && view.groupFields.length === 1) {
      if (view.groupFields[0].fieldRefName) {
        cols.push(
          this.tableColumnService.getDataModelColumn(
            view.recordType,
            view.groupFields[0]?.fieldRefName,
          ),
        );
      }
      for (const field of view.aggregateFields ?? []) {
        const tblCol = this.tableColumnService.getDataModelColumn(
          view.recordType,
          field.expression.map((x) => x.key ?? x.value).join(' '),
        );
        tblCol.propertyName = `${tblCol.propertyName}_${field.function.toUpperCase()}`;
        tblCol.displayName = field.label;
        if (field.function.toUpperCase() === 'COUNT') {
          tblCol.alignment = 'right';
        }
        cols.push(tblCol);
      }
      return [...cols];
    }
    if (!view.fields) {
      return cols;
    }
    for (const col of view.fields) {
      if (!col) {
        continue;
      }
      if (
        col.fieldRefName &&
        view.groupFields &&
        view.groupFields.length === 1 &&
        view.groupFields.at(0)?.fieldRefName === col.fieldRefName
      ) {
        continue;
      }
      if (
        col.name &&
        view.groupFields &&
        view.groupFields.length === 1 &&
        view.groupFields.at(0)?.name === col.name
      ) {
        continue;
      }

      const tblCol = this.tableColumnService.getDataModelColumn(
        view.recordType,
        (col.fieldRefName ?? col.mappedObject)!,
      );
      if (col.fieldLabel) {
        tblCol.displayName = col.fieldLabel;
      }
      cols.push(tblCol);
    }
    return [...cols];
  }

  private getDataModelFieldFromTableSchema(
    field: TableSchemaField,
  ): DataModelRecordTypeField | undefined {
    if (!field || !field.fieldId || !field.fieldRecordType) {
      return undefined;
    }

    return this.dataModelStore.getFieldFromRecordTypeByFieldId(
      field.fieldRecordType,
      field.fieldId,
    );
  }
}
