import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MetadataFieldType, RecordTypeFieldMetadata } from 'app/core/data-model/models/models';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { CustomValidators } from 'app/core/validators/custom-validators';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { FieldHideAreaFlags } from 'portal-commons/dist/data-model/record-types';
import { sortBy } from 'lodash-es';

@UntilDestroy()
@Component({
  selector: 'tb-datamodel-field-treeselect',
  templateUrl: './datamodel-field-treeselect.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatamodelFieldTreeSelectComponent implements OnInit {
  @Input() rootRecordTypeId$!: Observable<string>;
  @Input() inputValue$?: Observable<RecordTypeFieldMetadata | undefined>;
  @Input() rootFieldsOnly = false;
  @Input() includeListFields = false;
  @Input() disabled$?: Observable<boolean>;
  @Input() includeCoreFields = false;
  @Input() isRequired = false;
  @Input() additionalFieldClasses: string[] = [];
  @Input() restrictedArea: FieldHideAreaFlags | undefined = undefined;
  @Input() disableParentSelection = false;
  @Output() fieldchanged = new EventEmitter<RecordTypeFieldMetadata | null>();
  @Output() inputmatched = new EventEmitter<RecordTypeFieldMetadata | null>();

  fieldNodes$!: Observable<TreeNode<RecordTypeFieldMetadata>[]>;
  MAX_DEPTH = 3;

  selectedNodes: TreeNode<RecordTypeFieldMetadata>[] | undefined = undefined;

  constructor(private dataModelStore: DataModelStoreService, private _changes: ChangeDetectorRef) { }

  ngOnInit(): void {
    //this.loadingKey = `field-autocomplete-${crypto.randomUUID()}`;
    const validators: Validators[] = [CustomValidators.requireAutocompleteMatch];
    if (this.isRequired) {
      validators.push(Validators.required);
    }

    this.fieldNodes$ = this.rootRecordTypeId$.pipe(
      map((recordTypeId) => this.getFieldsFromRecordType(recordTypeId, true)),
    );

    if (this.inputValue$) {
      combineLatest(this.fieldNodes$, this.inputValue$)
        .pipe(
          untilDestroyed(this),
          tap((x) => {
            let nodes = x[0];
            const selectedField = x[1];
            if (nodes && selectedField) {
              let selectedNode;
              (selectedField.refName ?? '').split('.').forEach((key) => {
                selectedNode = nodes.find((x) => x.data?.refName === key);
                if (selectedNode && selectedNode.children) {
                  nodes = selectedNode.children;
                }
              });
              if (selectedNode) {
                this.selectedNodes = [selectedNode];
                this._changes.markForCheck();
              }
            } else {
              this.selectedNodes = undefined;
              this._changes.markForCheck();
            }
          }),
        )
        .subscribe();
    }

    this._changes.markForCheck();
  }

  getFieldsFromRecordType(
    recordTypeId: string,
    isRoot: boolean,
    additionalFields: RecordTypeFieldMetadata[] | null = null,
    parentNode: TreeNode<RecordTypeFieldMetadata> | undefined = undefined,
    depth = 1,
  ): TreeNode<RecordTypeFieldMetadata>[] {
    const fieldsSource = this.dataModelStore.getRecordTypeFieldsByRecordType(recordTypeId);
    if (!fieldsSource) {
      return [];
    }
    const recTypeFields = [...fieldsSource];
    if (!recTypeFields || recTypeFields.length === 0) {
      return [];
    }

    const nodes: TreeNode<RecordTypeFieldMetadata>[] = [];

    const fields = recTypeFields
      .filter(
        (f) =>
          (this.includeListFields || !(f.isList ?? false)) &&
          (this.restrictedArea === undefined ||
            f.hidden === undefined ||
            (f.hidden &&
              this.restrictedArea &&
              !f.hidden.includes('All') &&
              !f.hidden.includes(this.restrictedArea))),
      )
      .filter(
        (f) =>
          !(
            this.dataModelStore.getFieldFromRecordTypeByFieldId(recordTypeId, f.id!)
              ?.disableFieldFromFilter ?? false
          ),
      );

    for (const recField of fields) {
      const newNode: TreeNode<RecordTypeFieldMetadata> = {
        label: recField.label ?? undefined,
        data: {
          fieldType: MetadataFieldType.default,
          recordTypeId: recField.recordTypeId!,
          id: recField.id!,
          label: recField.label!,
          refName: recField.refName!,
          dataModelFieldType: recField.fieldType ?? undefined,
          formula: recField.formula ?? undefined,
        },
        parent: parentNode,
      };
      if (parentNode) {
        newNode.parent = parentNode;
      }

      if (recField.codeSet) {
        this.addCodeSetChildren(newNode);
      }

      const isRecordType =
        recField.fieldType && this.dataModelStore.recordTypeExists(recField.fieldType);

      newNode.type = isRecordType ? 'parent' : 'field';
      const listCodeField = recField.fieldType === 'codelist' && (recField.isList ?? false);
      if (listCodeField) {
        newNode.type = 'parent';
      }
      if (isRecordType && depth <= this.MAX_DEPTH && !(recField.isList ?? false)) {
        newNode.children = this.getFieldsFromRecordType(
          recField.fieldType!,
          false,
          null,
          newNode,
          depth + 1,
        );
      }
      newNode.selectable = !(this.disableParentSelection && isRecordType) && !listCodeField;
      nodes.push(newNode);
    }

    if (isRoot && additionalFields) {
      if (additionalFields) {
        for (const additionalField of additionalFields) {
          const newNode: TreeNode<RecordTypeFieldMetadata> = {
            label: additionalField.label ?? undefined,
            type: 'field',
            data: {
              ...additionalField,
            },
          };
          nodes.push(newNode);
        }
      }
    }
    return sortBy(nodes, 'label');
  }

  addCodeSetChildren(parentNode: TreeNode<RecordTypeFieldMetadata>) {
    if (!parentNode.data) {
      return;
    }
    if (!parentNode.children) {
      parentNode.children = [];
    }
    const items = !(parentNode.data.isList ?? false) ? ['description', 'codeWithDescription'] : ['description'];
    for (const addItem of items) {
      const useLabel =
        addItem === 'description'
          ? `${parentNode.data.label} (desc)`
          : `${parentNode.data.label} (code w/ desc)`;
      const newNode: TreeNode<RecordTypeFieldMetadata> = {
        label: useLabel,
        type: 'field',
        data: {
          fieldType: MetadataFieldType.default,
          recordTypeId: parentNode.data.recordTypeId,
          id: parentNode.data.id,
          label: parentNode.data.label,
          refName: addItem,
          dataModelFieldType: 'string',
          formula: undefined,
        },
        parent: parentNode,
      };
      parentNode.children.push(newNode);
    }
    parentNode.type = 'parent-link';
  }

  onNodeSelected(e: { node: TreeNode }) {
    const pathRefs = [e.node.data.refName];

    let nodeParent = e.node.parent;
    while (nodeParent) {
      pathRefs.push(nodeParent.data.refName);
      nodeParent = nodeParent.parent;
    }

    const adjustedField = {
      ...e.node.data,
      fieldRefName: pathRefs.reverse().join('.'),
    } as RecordTypeFieldMetadata;

    this.fieldchanged.emit(adjustedField);
  }
}
