var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from "../../data-filters/models";
import { RoleCategories } from "../../roleEnums";
import { TrailMapType } from "../../trail-maps/models";
import { FieldData, RecordTypeData } from "../meta-data-decorators";
const recordTypeId = 'Check';
export var CheckStatusEnum;
(function (CheckStatusEnum) {
    CheckStatusEnum["Draft"] = "draft";
    CheckStatusEnum["Printing"] = "printing";
    CheckStatusEnum["Issued"] = "issued";
    CheckStatusEnum["Cleared"] = "cleared";
    CheckStatusEnum["Voided"] = "voided";
    CheckStatusEnum["Cancelled"] = "cancelled";
})(CheckStatusEnum || (CheckStatusEnum = {}));
;
export const checkStatusList = [
    { code: CheckStatusEnum.Draft, description: 'Draft' },
    { code: CheckStatusEnum.Printing, description: 'Printing' },
    { code: CheckStatusEnum.Issued, description: 'Issued' },
    { code: CheckStatusEnum.Cleared, description: 'Cleared' },
    { code: CheckStatusEnum.Voided, description: 'Voided' },
    { code: CheckStatusEnum.Cancelled, description: 'Cancelled' },
];
let Check = class Check {
    id;
    bankAccountId;
    bankAccount;
    issueDate;
    clearDate;
    cancelDate;
    voidDate;
    printDate;
    status;
    amount;
    payeeName;
    payeeAddress;
    checkNumber;
    checkSequence;
    payeeContactId;
    payeeContact;
    checkBatchId;
    checkBatch;
    overridePayeeContactInfo;
    tin;
    notes;
    claimPayments;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Check.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'BankAccount' })
], Check.prototype, "bankAccountId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'BankAccount' })
], Check.prototype, "bankAccount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Check.prototype, "issueDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Check.prototype, "clearDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Check.prototype, "cancelDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Check.prototype, "voidDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Check.prototype, "printDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: checkStatusList })
], Check.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], Check.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId })
], Check.prototype, "payeeName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address' })
], Check.prototype, "payeeAddress", void 0);
__decorate([
    FieldData({ recordTypeId })
], Check.prototype, "checkNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], Check.prototype, "checkSequence", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Contact' })
], Check.prototype, "payeeContactId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Contact' })
], Check.prototype, "payeeContact", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'CheckBatch' })
], Check.prototype, "checkBatchId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'CheckBatch' })
], Check.prototype, "checkBatch", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], Check.prototype, "overridePayeeContactInfo", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'TIN' })
], Check.prototype, "tin", void 0);
__decorate([
    FieldData({ recordTypeId })
], Check.prototype, "notes", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'ClaimCheckPayment',
        isList: true,
        jsonStorage: true,
        disableField: true
    })
], Check.prototype, "claimPayments", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Check.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Check.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Check.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Check.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Check.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Check.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Check.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Check.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Check.prototype, "deletedUTC", void 0);
Check = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Check',
        displayNamePlural: 'Checks',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true,
            docTemplates: true
        },
        roleCategory: RoleCategories.Checks,
        recordHistoryConfig: {
            enable: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage],
        lookupProperties: {
            filterProperties: ['checkNumber', 'payeeContact.displayName'],
            resultProperties: ['payeeContact.displayName', 'issueDate', 'amount', 'checkNumber']
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'bankAccount', filterType: FilterType.Equals },
        ]
    })
], Check);
export { Check };
