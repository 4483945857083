var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from "../../data-filters/models";
import { RoleCategories } from "../../roleEnums";
import { FieldData, RecordTypeData } from "../meta-data-decorators";
const recordTypeId = 'CheckBatch';
export var CheckBatchStatusEnum;
(function (CheckBatchStatusEnum) {
    CheckBatchStatusEnum["Open"] = "open";
    CheckBatchStatusEnum["Complete"] = "complete";
    CheckBatchStatusEnum["Cancelled"] = "cancelled";
})(CheckBatchStatusEnum || (CheckBatchStatusEnum = {}));
;
export const checkBatchStatusList = [
    { code: CheckBatchStatusEnum.Open, description: 'Open' },
    { code: CheckBatchStatusEnum.Complete, description: 'Complete' },
    { code: CheckBatchStatusEnum.Cancelled, description: 'Cancelled' }
];
export var CheckBatchStepEnum;
(function (CheckBatchStepEnum) {
    CheckBatchStepEnum["Select"] = "select";
    CheckBatchStepEnum["VerifySelect"] = "verify-select";
    CheckBatchStepEnum["Generate"] = "generate";
    CheckBatchStepEnum["Print"] = "print";
    CheckBatchStepEnum["Confirm"] = "confirm";
})(CheckBatchStepEnum || (CheckBatchStepEnum = {}));
;
export const checkBatchStepList = [
    { code: CheckBatchStepEnum.Select, description: 'Select Records' },
    { code: CheckBatchStepEnum.VerifySelect, description: 'Verify Selected Records' },
    { code: CheckBatchStepEnum.Generate, description: 'Generate Batch' },
    { code: CheckBatchStepEnum.Print, description: 'Print' },
    { code: CheckBatchStepEnum.Confirm, description: 'Confirm' }
];
let CheckBatch = class CheckBatch {
    id;
    status;
    step;
    bankAccountId;
    bankAccount;
    batchSequence;
    numberOfChecks;
    amount;
    checks;
    checkSequencesUsed;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CheckBatch.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: checkBatchStatusList })
], CheckBatch.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeList: checkBatchStepList })
], CheckBatch.prototype, "step", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'BankAccount' })
], CheckBatch.prototype, "bankAccountId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'BankAccount' })
], CheckBatch.prototype, "bankAccount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', virtualDbField: true, label: 'Batch #' })
], CheckBatch.prototype, "batchSequence", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer', label: '# of Checks' })
], CheckBatch.prototype, "numberOfChecks", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], CheckBatch.prototype, "amount", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Check',
        isList: true,
        jsonStorage: true,
        disableField: true
    })
], CheckBatch.prototype, "checks", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', disableField: true, jsonStorage: true })
], CheckBatch.prototype, "checkSequencesUsed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CheckBatch.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CheckBatch.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], CheckBatch.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], CheckBatch.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], CheckBatch.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], CheckBatch.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], CheckBatch.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], CheckBatch.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], CheckBatch.prototype, "deletedUTC", void 0);
CheckBatch = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        hasBackingTable: true,
        displayNameSingular: 'Check Batch',
        displayNamePlural: 'Check Batches',
        supports: {
            files: true,
            notes: true,
            docTemplates: true
        },
        roleCategory: RoleCategories.Checks,
        recordHistoryConfig: {
            enable: true
        },
        dataModelCustomization: {
            allowCustomFields: false,
            hideFromAdmin: true
        },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'bankAccount', filterType: FilterType.Equals },
        ]
    })
], CheckBatch);
export { CheckBatch };
